html,
body {
  height: 100%;
  margin: 0;
}
#root {
  min-height: 100%;
  background-color: rgb(255, 255, 255) !important;
}

html.dark #root {
  background-color: rgb(0, 0, 0) !important;
}
